import React from 'react'

import SEO from '../components/Seo'
import ProjectAsset from '../components/ProjectAsset'
import ProjectText from '../components/ProjectText'

import { graphql } from 'gatsby'
import { motion } from 'framer-motion'

const container = {
  initial: {},
  exit: {},
  animate: {
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.05,
    },
  },
}

const fade = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
}

function Project({ data }) {
  const { assets, metaDescription, sharingImage, title, ...props } = data?.contentfulProject
  const siteTitle = data?.contentfulSite?.metaTitle
  const metaDescriptionOption = metaDescription || data?.contentfulSite.metaDescription
  const description = metaDescriptionOption.childMarkdownRemark.rawMarkdownBody

  const shareImage = sharingImage?.fixed?.src

  return (
    <motion.div variants={container} initial="initial" animate="animate">
      <SEO description={description} siteTitle={siteTitle} shareImage={shareImage} title={title} />

      <ProjectText title={title} {...props} />

      <motion.div variants={fade}>
        {!!assets &&
          !!assets.length &&
          assets.map((item, i) => (
            <ProjectAsset key={item.title + i} {...item}>
              {item.title}
            </ProjectAsset>
          ))}
      </motion.div>
    </motion.div>
  )
}

export default Project

export const pageQuery = graphql`
  query ProjectBySlug($slug: String!) {
    contentfulSite {
      backToIndexLabel
      links {
        title
        page {
          slug
        }
        externalUrl
      }
      siteTitle {
        json
      }
      siteTitleShort {
        json
      }
      metaDescription {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      metaTags
      metaTitle
    }
    contentfulProject(slug: { eq: $slug }) {
      title
      location
      slug
      role
      credits {
        json
      }
      sharingImage {
        file {
          url
        }
        fixed(width: 1200, height: 630, quality: 80) {
          height
          src
          width
        }
      }
      metaDescription {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      assets {
        title
        videoEmbed {
          childMarkdownRemark {
            html
          }
        }
        layout
        responsive
        videoWidth
        videoHeight
        images {
          title
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
          fixed(width: 2800, quality: 80) {
            src
            height
            width
            srcSet
            srcSetWebp
            srcWebp
            aspectRatio
          }
          fluid(maxWidth: 2800, maxHeight: 2800, quality: 80) {
            srcWebp
            srcSetWebp
            src
            sizes
            srcSet
            aspectRatio
          }
        }
      }
    }
  }
`
