import React from 'react'

import { TWO_UP_LEFT_WIDE, TWO_UP_RIGHT_WIDE, PADDING } from '../constants/layoutOptions'
import ReactHtmlParser from 'react-html-parser'

import styled, { css } from 'styled-components/macro'
import { rem } from 'polished'

//const MAX_HEIGHT = 1100
//const MAX_WIDTH = 1955

const Image = ({ fluid, fixed, file, title, single }) => {
  const size = file?.details?.image || {}

  return (
    <Mask single={single} {...size}>
      <picture>
        <source media={fluid.sizes} srcSet={fluid?.srcSetWebp} type="image/webp" />
        <source media={fluid.sizes} srcSet={fluid?.srcSet} type="image/jpeg" />
        <img src={fixed?.src} {...size} alt={title} />
      </picture>
    </Mask>
  )
}

function ProjectAsset({ images, videoEmbed, videoWidth, videoHeight, layout, responsive }) {
  return (
    <Container>
      {!!images && !!images.length && (
        <Center layout={layout}>
          <Grid layout={layout} responsive={!!responsive}>
            {images.map((item, i) => (
              <Image key={`img${i}`} single={images.length === 1} {...item} />
            ))}
          </Grid>
        </Center>
      )}
      {!!videoEmbed && (
        <Center layout={layout}>
          <Video width={videoWidth || 16} height={videoHeight || 9}>
            {ReactHtmlParser(videoEmbed.childMarkdownRemark.html)}
          </Video>
        </Center>
      )}
    </Container>
  )
}

export default ProjectAsset

const Container = styled.div(
  ({ theme }) => css`
    max-width: 100vw;
    overflow: hidden;
  `,
)

const gridLayout = ({ responsive, layout, theme }) => css`
  grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
  grid-template-rows: repeat(600px, auto);

  ${layout === TWO_UP_LEFT_WIDE &&
  css`
    ${!responsive &&
    css`
      grid-template-columns: 2fr 1fr;
    `};

    ${theme.media.min('md')} {
      grid-template-columns: 2fr 1fr;
    }
  `};

  ${layout === TWO_UP_RIGHT_WIDE &&
  css`
    ${!responsive &&
    css`
      grid-template-columns: 1fr 2fr;
    `};

    ${theme.media.min('md')} {
      grid-template-columns: 2fr 1fr;
    }
  `};
`

const Grid = styled.div`
  ${({ responsive, theme }) => css`
    display: grid;
    align-items: center;
    grid-template-columns: 1fr;

    width: 100vw;

    ${!responsive && gridLayout};

    ${theme.media.min('md')} {
      ${gridLayout};
    }
  `};
`

const Mask = styled.div`
  ${({ width, height, single, theme }) => css`
    position: relative;
    overflow: hidden;

    width: 100%;
    height: 100%;

    background-color: ${theme.color.rule};

    ${single &&
    css`
      width: 100vw;
      height: calc(100vw / (${width} / ${height}));
    `};

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `};
`

const Video = styled.div`
  ${({ width, height }) => css`
    position: relative;
    width: 100vw;
    height: calc(100vw / (${width} / ${height}));
    max-height: 95vh;
    max-width: calc(95vh / (${height} / ${width}));
    overflow: hidden;

    iframe {
      width: 101%;
      height: 101%;
    }
  `};
`

const Center = styled.div`
  ${({ layout, theme }) => css`
    display: flex;
    justify-content: center;
    width: 100%;

    ${layout === PADDING &&
    css`
      ${Mask} {
        padding: ${rem(10)};

        ${theme.media.min('sm')} {
          padding: ${rem(20)};
        }

        ${theme.media.min('md')} {
          padding: ${rem(40)};
        }
      }
    `};
  `};
`
