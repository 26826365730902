import React, { Fragment } from 'react'

import { MotionText } from './common/Text'
import Spacer from './common/Spacer'

import styled, { css } from 'styled-components/macro'
import { rem } from 'polished'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const fade = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
}

export default function ProjectText({ credits, location, role, title }) {
  return (
    <Container>
      <Left>
        <Line />

        <Spacer flex />
        <Line />
      </Left>

      <Spacer md={{ x: 2 }} />

      <Content>
        <LineSmall />
        <Spacer y={3} />
        {!!role && (
          <>
            <MotionText condensed uppercase variants={fade}>
              {role}
            </MotionText>
            <Spacer y={2} />
          </>
        )}
        {!!title && (
          <MotionText size="lg" condensedBold uppercase variants={fade}>
            {title}
          </MotionText>
        )}
        {!!location && (
          <MotionText condensed uppercase variants={fade}>
            {location}
          </MotionText>
        )}
        {!!credits && (
          <>
            <Spacer y={2} />
            <MotionText condensed uppercase variants={fade}>
              {documentToReactComponents(credits.json)}
            </MotionText>
          </>
        )}
        <Spacer y={3} />
        <LineSmall />
      </Content>

      <Left>
        <Line />

        <Spacer flex />
        <Line />
      </Left>
    </Container>
  )
}

const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;

    width: 100%;
    max-width: 900px;

    padding: ${rem(20)};

    ${theme.media.min('sm')} {
      padding: ${rem(30)};
    }

    ${theme.media.min('md')} {
      padding: ${rem(40)};
    }

    ${theme.media.min('lg')} {
      padding: ${rem(80)};
    }
  `};
`

const Content = styled.div`
  flex: 1;
  flex-shrink: 0;
`

const Line = styled.div`
  ${({ theme }) => css`
    width: ${rem(24)};
    height: ${rem(1)};
    background-color: ${theme.color.secondary};
  `}
`

const LineSmall = styled(Line)`
  ${({ theme }) => css`
    ${theme.media.min('md')} {
      display: none;
    }
  `}
`

const Left = styled.div`
  ${({ theme }) => css`
    display: none;

    ${theme.media.min('md')} {
      display: flex;
      flex-direction: column;
    }
  `}
`
